export default {
  config: {
    screens: {
      Register: '/register',
      Login: '/login',
      Landing: '/',
    },
  },
  getPathFromState(state, config) {
    return window.location.href;
  },
};
