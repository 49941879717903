import React, { Component } from 'react';
import { Dimensions } from 'react-native';
import { MobileContext } from '.';
import _ from 'lodash';

const MOBILE_WIDTH = 750;
const THROTTLE_DELAY = 500;

export class MobileProvider extends Component {
  state = {
    mobile: Dimensions.get('window').width <= MOBILE_WIDTH,
  };

  componentDidMount() {
    Dimensions.addEventListener(
      'change',
      _.throttle(
        ({ window }) => this.setState({ mobile: window.width <= MOBILE_WIDTH }),
        THROTTLE_DELAY,
      ),
    );
  }

  render() {
    const { children } = this.props;
    const { mobile } = this.state;

    return (
      <MobileContext.Provider value={mobile}>{children}</MobileContext.Provider>
    );
  }
}
