import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import Header from '../components/Header';
import HeroSection from '../components/LandingScreen/HeroSection';
import FeaturesSection from '../components/LandingScreen/FeaturesSection';
import DemoSection from '../components/LandingScreen/DemoSection';
import ScalingSection from '../components/LandingScreen/ScalingSection';
import BlockchainsSection from '../components/LandingScreen/BlockchainsSection';
import FooterSection from '../components/LandingScreen/FooterSection';

import WithMobile from '../mobile';

_insertWebTags();

if (window.location.hash) {
  alert('You successfully created or logged into a player account using the MetaFab auth flow. From here, your game would be able to grab the authenticated players access token, wallet decrypt key and id from the url fragment (#) argument and handle logging the user into your game. This is similar to a standard oauth 2.0 redirect flow.');
}

class LandingScreen extends Component {
  _renderHeader = () => {
    const { styles } = this.props;

    return (
      <Header style={styles.header} />
    );
  }

  _renderHero = () => {
    const { styles } = this.props;

    return (
      <HeroSection style={styles.heroSection} />
    );
  }

  _renderFeatures = () => {
    const { styles } = this.props;

    return (
      <FeaturesSection style={styles.featuresSection} />
    );
  }

  _renderDemo = () => {
    const { styles } = this.props;

    return (
      <DemoSection style={styles.demoSection} />
    );
  }

  _renderScaling = () => {
    const { styles } = this.props;

    return (
      <ScalingSection style={styles.scalingSection} />
    );
  }

  _renderBlockchains = () => {
    const { styles } = this.props;

    return (
      <BlockchainsSection style={styles.blockchainsSection} />
    );
  }

  _renderFooter = () => {
    return (
      <FooterSection />
    );
  }

  render() {
    const { styles, mobile } = this.props;

    return (
      <View style={styles.container}>
        {this._renderHeader()}
        {this._renderHero()}
        {this._renderFeatures()}
        {this._renderDemo()}
        {this._renderScaling()}
        {this._renderBlockchains()}
        {this._renderFooter()}

        <Image
          source={require('../assets/images/bg.jpg')}
          resizeMode={'cover'}
          style={styles.background}
        />
      </View>
    );
  }
}

export default WithMobile(LandingScreen, mobile => ({
  container: {
    flex: 1,
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    position: 'fixed',
    zIndex: -3,
  },
  header: {

  },
  heroSection: {
    marginTop: 140,
  },
  featuresSection: {
    marginTop: 100,
  },
  demoSection: {
    marginTop: 100,
  },
  scalingSection: {
    marginTop: 100,
  },
  blockchainsSection: {
    marginTop: 100,
  },
}));

function _insertWebTags() {
  document.title = 'MetaFab';

  _insertMetaTagName('twitter:card', 'summary_large_image');
  _insertMetaTag('twitter:site', '@tryMetaFab');
  _insertMetaTag('twitter:title', 'MetaFab - Blockchain Gaming APIs');
  _insertMetaTag('twitter:image', 'https://metafab-public-assets.s3.amazonaws.com/metafab-twitter.jpg');
  _insertMetaTag('og:title', 'MetaFab - Blockchain Gaming APIs');
  _insertMetaTag('og:image', 'https://metafab-public-assets.s3.amazonaws.com/metafab-twitter.jpg');
  _insertMetaTag('og:description', 'MetaFab provides game developers everything they need to make truly frictionless crypto-games using open, industry proven and developer focused technologies.');
}

function _insertMetaTagName(name, content) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('name', name);
  metaTag.content = content;
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}

function _insertMetaTag(property, content) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('property', property);
  metaTag.content = content;
  document.getElementsByTagName('head')[0].appendChild(metaTag);
}
