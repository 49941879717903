import React, { Component } from 'react';
import { View } from 'react-native';

import WithMobile from '../mobile';

class AuthScreen extends Component {
  componentDidMount() {
    if (window.location.href.includes('register')) {
      window.location = 'https://dashboard.trymetafab.com/auth/register';
    } else {
      window.location = 'https://dashboard.trymetafab.com/auth/login';
    }
  }

  render() {
    return (
      <View />
    );
  }
}

export default WithMobile(AuthScreen, mobile => ({}));
