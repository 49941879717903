import React, { Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import * as Font from 'expo-font';
import linking from './src/navigators/linking';
import RootNavigator from './src/navigators/RootNavigator';
import { MobileProvider } from './src/mobile/Provider';

document.title = 'MetaFab';

export default class App extends Component {
  state = {
    fontLoaded: false,
  }

  async componentDidMount() {
    await Font.loadAsync({
      InterBold: require('./src/assets/fonts/Inter-Bold.ttf'),
      InterSemiBold: require('./src/assets/fonts/Inter-Medium.ttf'),
    });

    _insertWebScripts();

    this.setState({ fontLoaded: true });
  }

  render() {
    return (
      <MobileProvider>
        <NavigationContainer
          linking={linking}
          documentTitle={{
            enabled: true,
            formatter: (options, route) => 'MetaFab',
          }}
          theme={{ colors: { background: '#000000' } }}
        >
          <RootNavigator />
        </NavigationContainer>
      </MobileProvider>
    );
  }
}

const _insertWebScripts = () => {
  const gtagScript = document.createElement('script');
  gtagScript.type = 'text/javascript';
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-XT73M2HRZ7';
  gtagScript.async = true;

  document.head.appendChild(gtagScript);

  const gaScript = document.createElement('script');
  gaScript.type = 'text/javascript';
  gaScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-XT73M2HRZ7');
  `;

  document.head.appendChild(gaScript);
};
