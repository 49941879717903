import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import WithMobile from '../mobile';
import { LinearGradient } from 'expo-linear-gradient';

class Button extends Component {
  render() {
    const { styles, style, iconComponent, showArrow, children, ...props } = this.props;

    return (
      <TouchableOpacity style={[ styles.container, style ]} {...props}>
        <View style={styles.content}>
          <Text style={styles.text}>{children}</Text>

          {showArrow && (
            <Image
              source={require('../assets/images/right-arrow.png')}
              resizeMode={'contain'}
              style={styles.arrowIconImage}
            />
          )}
        </View>

        <LinearGradient
          start={{ x: 0.0, y: 1.0 }}
          end={{ x: 1.0, y: 1.0 }}
          colors={[ '#0E0D0D', '#211F1F' ]}
          style={styles.gradient}
        />

        <LinearGradient
          start={{ x: 0.0, y: 1.0 }}
          end={{ x: 1.0, y: 1.0 }}
          colors={[ '#A238E6', '#DE3975', '#EA877D' ]}
          style={styles.borderGradient}
        />
      </TouchableOpacity>
    );
  }
}

export default WithMobile(Button, mobile => ({
  container: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    borderRadius: 10,
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
  },
  text: {
    fontFamily: 'InterBold',
    fontSize: 16,
    color: '#EEEFFB',
  },
  gradient: {
    position: 'absolute',
    top: 1,
    right: 1,
    bottom: 1,
    left: 1,
    zIndex: -1,
    borderRadius: 10,
  },
  borderGradient: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 10,
    zIndex: -2,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  arrowIconImage: {
    marginLeft: 10,
    height: 10,
    width: 12,
  },
}));
