import React, { Component } from 'react';
import { View } from 'react-native';
import WithMobile from '../../mobile';
import Heading from '../Heading';
import Subtext from '../Subtext';
import Button from '../Button';
import Card from '../Card';

class ScalingSection extends Component {
  render() {
    const { styles, style } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <Heading small>We put in the time, money & headache, so you don't have to</Heading>
        <Subtext>Reliable, scalable & secure systems; battle tested to handle 100,000's of active players and 10,000+ transactions per second, per game.</Subtext>
        <Button onPress={() => window.open('https://docs.trymetafab.com/docs/understanding-gasless-transactions', '_blank')} showArrow style={styles.ctaButton}>Learn More</Button>

        <View style={styles.cardContainer}>
          <Card
            title={'Get to Market Faster'}
            description={'Offset months of ground-up smart contract and systems development with our simple but powerful REST APIs and SDKs that cover 95% of blockchain implementation and design patterns for games in just a few lines of code.'}
            linkText={'View Our SDKs'}
            linkUrl={'https://docs.trymetafab.com/docs/language-framework-sdks'}
            style={styles.card}
          />
          <Card
            title={'Focus on Building a Great Game'}
            description={`Reallocate the 10's to 100's of thousands saved on blockchain R&D toward more developers, designers, and areas that scale, not toward building, writing, rewriting & re-rewriting efficient and immutable contracts & systems.`}
            linkText={'View Our Documentation'}
            linkUrl={'https://docs.trymetafab.com/docs/getting-started'}
            style={styles.card}
          />
        </View>
      </View>
    );
  }
}

export default WithMobile(ScalingSection, mobile => ({
  container: {
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  cardContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: !mobile ? 50 : 20,
  },
  card: {
    height: !mobile ? 230 : undefined,
    width: !mobile ? 450 : '100%',
    paddingVertical: !mobile ? 40 : 30,
    marginHorizontal: 10,
    marginTop: !mobile ? 0 : 30,
  },
  ctaButton: {
    marginTop: 20,
  },
}));
