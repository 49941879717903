import React, { Component } from 'react';
import { View, Image, Text, TouchableOpacity, Animated, StyleSheet } from 'react-native';
import Button from './Button';
import WithMobile from '../mobile';

class Header extends Component {
  state = {
    backgroundOpacityAnimated: new Animated.Value(0),
  }

  animatingShow = false;
  animatingHide = true;

  componentDidMount() {
    window.onscroll = () => {
      const yPos = document.body.scrollTop || document.documentElement.scrollTop;

      this._toggleHeaderBackground(yPos > 50);
    };
  }

  _toggleHeaderBackground = show => {
    if ((this.animatingShow && !show) || (this.animatingHide && show)) {
      this.animatingShow = show;
      this.animatingHide = !show;

      Animated.timing(this.state.backgroundOpacityAnimated, {
        toValue: show ? 1 : 0,
        duration: 250,
        useNativeDriver: true,
      }).start();
    }
  }

  _openDocumentation = () => {
    window.location = 'https://docs.trymetafab.com/';
  }

  _openSDKs = () => {
    window.location = 'https://docs.trymetafab.com/docs/language-framework-sdks';
  }

  _openBlog = () => {
    alert('Blog coming soon!');
  }

  _openSupport = () => {
    window.open('https://discord.gg/metafab', '_blank');
  }

  _openLogin = () => {
    window.location = 'https://dashboard.trymetafab.com/auth/login';
  }

  _openRegister = () => {
    window.location = 'https://dashboard.trymetafab.com/auth/register';
  }

  render() {
    const { styles, style, mobile } = this.props;
    const { backgroundOpacityAnimated } = this.state;

    return (
      <>
        <TouchableOpacity onPress={() => window.location = 'https://hychain.com'} style={styles.acquisition}>
          <Text style={styles.acquisitionText}>MetaFab is joining HYCHAIN 🎉 <Text style={styles.learnMoreText}>Learn More</Text></Text>
        </TouchableOpacity>
        <View style={[ styles.container, style ]}>
          <View style={styles.section}>
            {!mobile && (
              <>
                <TouchableOpacity onPress={() => window.location = 'https://docs.trymetafab.com/docs/getting-started'} style={styles.link}>
                  <Text style={styles.linkText}>Docs</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={this._openSDKs} style={styles.link}>
                  <Text style={styles.linkText}>SDKs</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => window.location = 'https://docs.trymetafab.com/reference'} style={styles.link}>
                  <Text style={styles.linkText}>API Reference</Text>
                </TouchableOpacity>

                {/*}
                <TouchableOpacity onPress={this._openBlog} style={styles.link}>
                  <Text style={styles.linkText}>Blog</Text>
                </TouchableOpacity>
                {*/}

                <TouchableOpacity onPress={this._openSupport} style={styles.link}>
                  <Text style={styles.linkText}>Support</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => window.location = 'https://twitter.com/tryMetaFab'} style={styles.link}>
                  <Image
                    source={require('../assets/images/twitter.png')}
                    resizeMode={'contain'}
                    style={styles.iconImage}
                  />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => window.location = 'https://discord.gg/metafab'} style={styles.link}>
                  <Image
                    source={require('../assets/images/discord.png')}
                    resizeMode={'contain'}
                    style={styles.iconImage}
                  />
                </TouchableOpacity>
              </>
            )}
          </View>

          <View style={[ styles.section, { justifyContent: 'center' } ]}>
            <TouchableOpacity onPress={() => window.location = '/'}>
              <Image
                source={require('../assets/images/logo.png')}
                resizeMode={'contain'}
                style={styles.logoImage}
              />
            </TouchableOpacity>
          </View>

          <View style={[ styles.section, { justifyContent: 'flex-end' } ]}>
            {!mobile && (
              <>
                <TouchableOpacity onPress={this._openLogin} style={styles.link}>
                  <Text style={styles.linkText}>Login</Text>
                </TouchableOpacity>

                <Button onPress={this._openRegister} showArrow>
                  Get Started
                </Button>
              </>
            )}
          </View>

          <Animated.View style={[ styles.containerBackground, { opacity: backgroundOpacityAnimated } ]} />
        </View>
      </>
    );
  }
}

export default WithMobile(Header, mobile => ({
  acquisition: {
    alignItems: 'center',
    backgroundColor: '#000000',
    position: 'fixed',
    height: 40,
    justifyContent: 'center',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
  },
  acquisitionText: {
    fontFamily: 'InterSemiBold',
    fontSize: 16,
    color: '#FFFFFF',
    textShadow: '0 3px 7px rgba(0, 0, 0, 0.2)',
  },
  learnMoreText: {
    textDecorationLine: 'underline'
  },
  container: {
    paddingHorizontal: 40,
    paddingVertical: 15,
    flexDirection: 'row',
    position: 'fixed',
    top: 40,
    right: 0,
    left: 0,
    zIndex: 2,
  },
  containerBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#040405',
    borderBottomColor: 'rgba(255, 255, 255, 0.1)',
    borderBottomWidth: 1,
    zIndex: -1,
  },
  section: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoImage: {
    height: 28,
    width: 163,
    marginTop: mobile ? 20 : 0,
  },
  link: {
    height: 30,
    justifyContent: 'center',
    marginRight: 25,
  },
  linkText: {
    fontFamily: 'InterSemiBold',
    fontSize: 16,
    color: 'rgba(238, 239, 255, 0.7)',
    textShadow: '0 3px 7px rgba(0, 0, 0, 0.2)',
  },
  row: {
    flexDirection: 'row',
  },
  iconImage: {
    height: 25,
    width: 25,
    opacity: 0.7,
  },
}));
