import React, { Component } from 'react';
import { View, Image } from 'react-native';
import WithMobile from '../../mobile';
import Heading from '../Heading';
import Subtext from '../Subtext';
import Button from '../Button';

class BlockchainsSection extends Component {
  render() {
    const { styles, style } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <Heading small>Take your game & our{'\n'}tools anywhere</Heading>
        <Subtext>Build programmatically using your preferred languages across popular blockchains with one vertically integrated infrastructure suite - MetaFab.</Subtext>
        <Button onPress={() => window.open('https://docs.trymetafab.com/docs/language-framework-sdks', '_blank')} showArrow style={styles.ctaButton}>View Developer SDKs</Button>

        <Image
          source={require('../../assets/images/chains.png')}
          resizeMode={'contain'}
          style={styles.chainsImage}
        />
      </View>
    );
  }
}

export default WithMobile(BlockchainsSection, mobile => ({
  container: {
    alignItems: 'center',
    paddingHorizontal: 15,
    'overflow-x': !mobile ? undefined : 'hidden',
  },
  card: {
    height: 230,
    width: 450,
    paddingVertical: 40,
    marginHorizontal: 10,
  },
  ctaButton: {
    marginTop: 20,
  },
  chainsImage: {
    height: !mobile ? 359 : 224,
    width: !mobile ? 1200 : 750,
    marginTop: !mobile ? -70 : -30,
    zIndex: -1,
  },
}));
