import React, { Component } from 'react';
import { View } from 'react-native';
import WithMobile from '../../mobile';
import Heading from '../Heading';
import Subtext from '../Subtext';
import Button from '../Button';
import Card from '../Card';

class FeaturesSection extends Component {
  render() {
    const { styles, style } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <Heading small>The solutions we wish we had when building our games</Heading>
        <Subtext>Interacting with blockchain systems should feel familiar for both players and developers. Read more about how our suite of tools makes this possible:</Subtext>
        <Button onPress={() => window.open('https://docs.trymetafab.com/docs/getting-started', '_blank')} showArrow style={styles.ctaButton}>
          View All Developer Docs
        </Button>

        <View style={styles.features}>
          <Card
            iconImageSource={require('../../assets/images/icon-potion.png')}
            title={'No Friction For Players Or Developers'}
            description={'Blend blockchain invisibly into the backbone of your games. Designed for all developers, we support 10+ programming languages and have complete Unreal Engine & Unity SDKs.'}
            linkText={'View All SDKs'}
            linkUrl={'https://docs.trymetafab.com/docs/language-framework-sdks'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-player.png')}
            title={'Player Management, Registration & Auth'}
            description={'Streamline player authentication & registration through our custom white-label onboarding flow. Set, update & manage any game-related player data - no database required.'}
            linkText={'Learn About Auth Systems'}
            linkUrl={'https://docs.trymetafab.com/docs/custom-auth-registration-page'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-wallet.png')}
            title={'Managed Wallets & External Wallets'}
            description={'Generate player wallets automatically & support external wallets (EOAs) through Metamask, Coinbase Wallet & WalletConnect. Write one set of code for interactions with any wallet type.'}
            linkText={'Learn About Player Wallets'}
            linkUrl={'https://docs.trymetafab.com/docs/player-management'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-lightning.png')}
            title={'Gasless & Fully Managed Transactions'}
            description={'Eliminate obtrusive gas fees, wallet popups & prompts during gameplay through our systems that forward, sign, send, prioritize, retry & verify transactions automatically.'}
            linkText={'Learn About Transactions'}
            linkUrl={'https://docs.trymetafab.com/docs/transaction-management'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-currency.png')}
            title={'Game Currencies (ERC20)'}
            description={'Launch blockchain-based in-game currencies with one line of code. Design, monitor & fine-tune your economy with real-time visibility into how players engage and transact.'}
            linkText={'Learn About Game Currencies'}
            linkUrl={'https://docs.trymetafab.com/docs/game-currencies'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-item.png')}
            title={'Digital Collectibles & Items (ERC1155)'}
            description={'Create & manage tokenized weapons, skins, materials, consumable or other items. Enable traditional player experiences while interacting with web3 systems under the hood.'}
            linkText={'Learn About Items'}
            linkUrl={'https://docs.trymetafab.com/docs/items-and-item-collections'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-crafting.png')}
            title={'Shops & Item Crafting (On-Chain)'}
            description={'Launch highly configurable shop and crafting systems in under 5 minutes to allow players to purchase, sell and craft in game items with your game currency or other currencies.'}
            linkText={'Learn About Shops & Crafting'}
            linkUrl={'https://docs.trymetafab.com/docs/shops-system'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-lootbox.png')}
            title={'Lootboxes\n(On-Chain)'}
            description={'Create & control looboxes with mechanics that use weighted probabilities to determine randomized items or rewards. Set item or progress prerequisites to earn or open.'}
            linkText={'Learn About Lootboxes'}
            linkUrl={'https://docs.trymetafab.com/docs/lootboxes-system'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-interoperable.png')}
            title={'Full Interoperability Between Games'}
            description={'Leverage infrastructure that works seamlessly across partner ecosystems and other MetaFab games. Limitless opportunities for cross-pollination and collaboration.'}
            linkText={'Learn About Interoperability'}
            linkUrl={'https://docs.trymetafab.com/docs/game-interoperability'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-contract.png')}
            title={'Contract Ownership (No Lock-In)'}
            description={'Transfer ownership of contracts deployed with MetaFab to an external wallet. Full autonomy to revoke our role as your infrastructure partner; whenever you want.'}
            linkText={'Learn About No Lock-In'}
            linkUrl={'https://docs.trymetafab.com/docs/contract-ownership-control'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-diamond.png')}
            title={'Free Without The Premium'}
            description={'Ditch the limits, paywalls, restrictions, & upfront or hidden fees. We want our tech to be open, accessible, & free without a creativity and adoption limiting monetization model.'}
            linkText={'Learn About Business Model'}
            linkUrl={'https://docs.trymetafab.com/docs/free-pricing-business-model'}
            style={styles.feature}
          />

          <Card
            iconImageSource={require('../../assets/images/icon-plus-person.png')}
            title={'Community Driven & Developed'}
            description={'Believe in a team that listens, moves fast, & loves what they do. We listen to our community, co-create practical solutions, and are passionate about building a better way to web3.'}
            linkText={'Learn About Our Philosophy'}
            linkUrl={'https://docs.trymetafab.com/docs/architecture-philosophy'}
            style={styles.feature}
          />
        </View>
      </View>
    );
  }
}

export default WithMobile(FeaturesSection, mobile => ({
  container: {
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  features: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 50,
    maxWidth: 1200,
  },
  feature: {
    width: !mobile ? 300 : '100%',
    height: 250,
    margin: 10,
  },
  ctaButton: {
    marginTop: 20,
  },
}));
