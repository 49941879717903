import React, { Component } from 'react';
import { View } from 'react-native';
import WithMobile from '../../mobile';
import Heading from '../Heading';
import Subtext from '../Subtext';
import Button from '../Button';

class DemoSection extends Component {
  state = {
    play: false,
  }

  _play = () => {
    const { mobile } = this.props;

    if (mobile) {
      return alert('This demo is not supported on mobile devices. Please view this page on a desktop computer.');
    }

    this.setState({ play: true });
  }

  render() {
    const { styles, style, mobile } = this.props;
    const { play } = this.state;

    return (
      <View style={[ styles.container, style ]}>
        <Heading small>See MetaFab in action</Heading>
        <Subtext>Check out Arcane Survivor, a demo game implementing MetaFab products like player authentication, gasless transactions, game currencies, tokenized items, shops, crafting, & more. Built in under a week by a developer with no prior blockchain experience.</Subtext>

        <View style={styles.demoContainer}>
          {play && (
            <iframe
              src={'https://v6p9d9t4.ssl.hwcdn.net/html/6828646/index.html'}
              webkitallowfullscreen
              msallowfullscreen
              scrolling={'no'}
              allow={'autoplay; fullscreen *; gamepad; xr; cross-origin-isolated'}
              allowFullScreen
              allowtransparency
              frameBorder={0}
              style={{
                width: !mobile ? 960 : '100%',
                height: !mobile ? 640 : 300,
              }}
            />
          )}

          {!play && (
            <Button onPress={this._play}>Play Arcane Survivor</Button>
          )}
        </View>
      </View>
    );
  }
}

export default WithMobile(DemoSection, mobile => ({
  container: {
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  demoContainer: {
    alignItems: 'center',
    backgroundColor: '#000000',
    width: !mobile ? 960 : '100%',
    height: !mobile ? 640 : 300,
    justifyContent: 'center',
    overflow: 'hidden',
    borderColor: '#1E1B25',
    borderWidth: 1,
    borderRadius: 10,
    marginTop: 60,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  },
}));
