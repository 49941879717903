import React, { Component } from 'react';
import { Text } from 'react-native';
import WithMobile from '../mobile';

class Heading extends Component {
  render() {
    const { styles, style, small, children } = this.props;

    return (
      <Text style={[
        styles.headingText,
        small ? styles.smallText : undefined,
        style,
      ]}>
        {children}
      </Text>
    );
  }
}

export default WithMobile(Heading, mobile => ({
  headingText: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'InterBold',
    fontSize: !mobile ? 54 : 44,
    lineHeight: !mobile ? 54 : 48,
    textShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    maxWidth: 700,
  },
  smallText: {
    fontSize: !mobile ? 40 : 32,
    lineHeight: !mobile ? 44 : 36,
  },
}));
