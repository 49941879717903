import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import WithMobile from '../../mobile';
import Heading from '../Heading';
import Button from '../Button';
import { LinearGradient } from 'expo-linear-gradient';

class ScalingSection extends Component {
  render() {
    const { styles, style, mobile } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <Heading small>Ready to build?{!mobile ? '\n' : ' '}Get started today</Heading>

        <View style={styles.buttons}>
          <Button onPress={() => window.location = '/register'} showArrow style={styles.button}>Get Started, We're Free</Button>
          <Button onPress={() => window.open('https://docs.trymetafab.com/docs/getting-started', '_blank')} showArrow style={styles.button}>View Our Dev Docs</Button>
          <Button onPress={() => window.open('https://calendly.com/metafab-erick/intro-call-with-metafab', '_blank')} showArrow style={styles.button}>Schedule an Intro Call</Button>
          <Button onPress={() => window.open('https://discord.gg/metafab', '_blank')} showArrow style={styles.button}>Join Discord</Button>

        </View>

        <LinearGradient
          start={{ x: 1.0, y: 1.0 }}
          end={{ x: 0, y: 1.0 }}
          locations={[ 0.04, 0.26, 0.95 ]}
          colors={[ '#D6A9F9', '#9C56D2', '#7E56D2' ]}
          style={{ ...StyleSheet.absoluteFillObject, zIndex: -1 }}
        />
      </View>
    );
  }
}

export default WithMobile(ScalingSection, mobile => ({
  container: {
    alignItems: 'center',
    backgroundColor: '#7E56D2',
    paddingVertical: 120,
  },
  buttons: {
    flexDirection: !mobile ? 'row' : undefined,
  },
  button: {
    marginHorizontal: 10,
    marginTop: 30,
  },
}));
