import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import WithMobile from '../mobile';
import { LinearGradient } from 'expo-linear-gradient';

class Card extends Component {
  render() {
    const { styles, iconImageSource, title, description, linkText, linkUrl, style } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <View style={styles.row}>
          {!!iconImageSource && (
            <View style={styles.iconContainer}>
              <Image
                source={iconImageSource}
                resizeMode={'contain'}
                style={styles.iconImage}
              />
            </View>
          )}

          <Text style={styles.titleText}>{title}</Text>
        </View>

        <Text style={styles.infoText}>{description}</Text>

        {!!linkUrl && (
          <TouchableOpacity onPress={() => window.location = linkUrl} style={styles.linkButton}>
            <Text style={styles.linkButtonText}>{linkText}</Text>

            <Image
              source={require('../assets/images/right-arrow.png')}
              resizeMode={'contain'}
              style={styles.linkArrowIconImage}
            />
          </TouchableOpacity>
        )}

        <LinearGradient
          start={{ x: 1.0, y: 0.0 }}
          end={{ x: 1.0, y: 1.0 }}
          colors={[ '#1E1B25AA', '#000000' ]}
          style={styles.gradient}
        />
      </View>
    );
  }
}

export default WithMobile(Card, mobile => ({
  container: {
    borderColor: 'rgba(0, 0, 0, 0.7)',
    borderWidth: 1,
    borderRadius: 10,
    overflow: 'hidden',
    padding: 20,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  },
  iconContainer: {
    alignItems: 'center',
    backgroundColor: 'linear-gradient(180deg, rgba(30, 27, 37, 0.8) 9.81%, rgba(0, 0, 0, 0) 55.53%)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderWidth: 1,
    borderRadius: 15,
    marginRight: 15,
    height: 60,
    justifyContent: 'center',
    width: 60,
  },
  iconImage: {
    height: 50,
    width: 50,
  },
  titleText: {
    fontFamily: 'InterBold',
    fontSize: 16,
    lineHeight: 22,
    color: '#FFFFFF',
    textShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
  },
  infoText: {
    fontFamily: 'InterSemiBold',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 15,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  linkButton: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 5,
    paddingVertical: 10,
  },
  linkButtonText: {
    fontFamily: 'InterBold',
    fontSize: 14,
    lineHeight: 20,
    color: 'rgba(255, 255, 255, 0.8)',
  },
  linkArrowIconImage: {
    opacity: 0.8,
    marginLeft: 6,
    height: 9,
    width: 11,
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -1,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
}));
