import React from 'react';
import { StyleSheet } from 'react-native';

export const MobileContext = React.createContext();

const WithMobile = (Component, styles) => {
  return (props) => (
    <MobileContext.Consumer>
      {(value) => {
        return (
          <Component
            {...props}
            mobile={value}
            styles={StyleSheet.create(styles(value))}
          />
        );
      }}
    </MobileContext.Consumer>
  );
};

export default WithMobile;
