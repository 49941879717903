import React, { Component } from 'react';
import { Text } from 'react-native';
import WithMobile from '../mobile';

class Subtext extends Component {
  render() {
    const { styles, style, children } = this.props;

    return (
      <Text style={[ styles.subText, style ]}>
        {children}
      </Text>
    );
  }
}

export default WithMobile(Subtext, mobile => ({
  subText: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: 'InterSemiBold',
    fontSize: 18,
    lineHeight: 26,
    marginTop: 30,
    maxWidth: 700,
    textAlign: 'center',
    textShadow: '0 3px 7px rgba(0, 0, 0, 0.2)',
  },
}));
