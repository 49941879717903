import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import LandingScreen from '../screens/LandingScreen';
import AuthScreen from '../screens/AuthScreen';

export default () => {
  const RootStack = createStackNavigator();

  return (
    <RootStack.Navigator
      headerMode={'none'}
      screenOptions={{ cardStyle: { overflow: 'visible' } }}
    >
      <RootStack.Screen
        name={'Landing'}
        component={LandingScreen}
      />

      <RootStack.Screen
        name={'Register'}
        component={AuthScreen}
      />

      <RootStack.Screen
        name={'Login'}
        component={AuthScreen}
      />
    </RootStack.Navigator>
  );
};
