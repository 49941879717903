import React, { Component } from 'react';
import { View, Image } from 'react-native';
import WithMobile from '../../mobile';
import Heading from '../Heading';
import Subtext from '../Subtext';
import Button from '../Button';

class HeroSection extends Component {
  render() {
    const { styles, style } = this.props;

    return (
      <View style={[ styles.container, style ]}>
        <Heading>Frictionless blockchain games build with MetaFab</Heading>
        <Subtext>The free, end-to-end, self-serve solution for games and gamified apps to integrate powerful blockchain infrastructure in minutes, not months.</Subtext>

        <View style={styles.buttons}>
          <Button onPress={() => window.location = 'https://dashboard.trymetafab.com/auth/register'} showArrow style={styles.ctaButton}>
            Sign Up, We're Free
          </Button>

          <Button onPress={() => window.open('https://calendly.com/metafab-erick/intro-call-with-metafab', '_blank')} showArrow style={styles.ctaButton}>
            Book an Intro Call
          </Button>
        </View>

        <Image
          source={require('../../assets/images/hero.png')}
          resizeMode={'contain'}
          style={styles.heroImage}
        />
      </View>
    );
  }
}

export default WithMobile(HeroSection, mobile => ({
  container: {
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  heroImage: {
    height: !mobile ? 450 : 213,
    width: !mobile ? 804 : 380,
    marginTop: 70,
  },
  buttons: {
    flexDirection: !mobile ? 'row' : undefined,
  },
  ctaButton: {
    maxWidth: 300,
    marginTop: 20,
    marginHorizontal: !mobile ? 15 : 0,
  },
}));
